<template>
  <div>
    <div>
      <v-switch
          v-model="auto_generate"
          :label="$t('password.generate.auto')"
          color="primary"
      ></v-switch>
    </div>
    <div v-if="auto_generate">
      <h3>{{ $t('password.generate.auto') }}</h3>
      <div class="mt-4 d-flex">
        <h2 style="border: 1px solid #cecece; width: 200px; text-align: center" class="pa-3">
          {{ password }}
        </h2>
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="ml-4"
            elevation="2"
            color="primary"
            medium
            @click="reloadPassword"
        >
          <v-icon size="32">mdi-autorenew</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('password.generate.manuel') }}</h3>
      <div class="mt-4">
        <v-text-field
            v-model="password"
            outlined
            :label="$t('customer.password')"
            :placeholder="$t('customer.password')"
            required
        ></v-text-field>
        <v-text-field
            v-model="password_confirmation"
            outlined
            :label="$t('customer.password_confirmation')"
            :placeholder="$t('customer.password_confirmation')"
            required
        ></v-text-field>
        <v-btn
            class="mt-1"
            x-large color="primary"
            :disabled="button_disabled"
            @click="getPassword"
        >
          {{ $t('customer.buttons.password_create') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordGenerator",
  props: {
    status_auto_generate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      password: '',
      password_confirmation: '',
      auto_generate: this.status_auto_generate,
      manuel_generate: false,
      button_disabled: true,
    }
  },
  watch: {
    auto_generate() {
      if (!this.auto_generate) {
        this.password = ''
        this.password_confirmation = ''
        this.manuel_generate = true
        this.getPassword()
      } else {
        this.manuel_generate = false
        this.generatePassword()
        this.getPassword()
      }
    },
    password_confirmation() {
      if (this.password_confirmation === this.password) {
        this.button_disabled = false
      } else {
        this.button_disabled = true
      }
    },
    password() {
      if (this.password_confirmation === this.password) {
        this.button_disabled = false
      } else {
        this.button_disabled = true
      }
    }
  },
  created() {
    if (this.status_auto_generate) {
      this.generatePassword()
      this.getPassword()
    }
  },
  methods: {
    generatePassword() {
      let length = 10
      let retVal = "";
      let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!$&?ß#"

      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal
    },
    reloadPassword() {
      this.generatePassword()
      this.getPassword()
    },
    getPassword() {
      this.$emit('getPassword', this.password)
    }
  }
}
</script>
